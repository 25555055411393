/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Modal, Row, Col, Alert } from 'react-bootstrap'
import _ from 'lodash'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import BookingContext, { CalendarContext } from './booking.context'
import loginContext from '../login/login.context'
import LoginModal from '../login/loginModal'
import { hasSessionSlotQuery, isEnrolledQuery } from './gql'

export default function Index() {
  const { forBooking, setForBooking } = useContext(CalendarContext)
  const { tutor, tutorProfile } = useContext(BookingContext)
  const [visible, setVisible] = useState(false)
  const [timeSlotUid, setTimeSlotUid] = useState(null)
  const [title, setTitle] = useState(null)
  const [price, setPrice] = useState(0)
  const [sessionType, setSessionType] = useState(null)
  const [dateRange, setDateRange] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [canBook, setCanBook] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const { userUid, roleCode, instanceUid } = useContext(loginContext)
  const navigate = useNavigate()
  const [enrolled, setEnrolled] = useState(false)
  const [isAvailable, setIsAvailable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isFullyBooked, setIsFullyBooked] = useState(false)

  useEffect(() => {
    if (forBooking) {
      setVisible(true)
    } else {
      setVisible(false)
    }

    const available = _.has(forBooking, 'available')
      ? forBooking.available
      : false

    setTimeSlotUid(_.has(forBooking, 'timeslotUid') && forBooking.timeslotUid)
    setTitle(_.has(forBooking, 'title') && forBooking.title)
    setPrice(_.has(forBooking, 'price') && forBooking.price)
    setSessionType(_.has(forBooking, 'sessionType') && forBooking.sessionType)

    const start = _.has(forBooking, 'start') ? forBooking.start : null
    const end = _.has(forBooking, 'end') ? forBooking.end : null
    const dateRangeString = `${moment(start).format('lll')} - ${moment(
      end
    ).format('lll')}`

    setStartDate(start)
    setEndDate(end)
    setDateRange(dateRangeString)

    setCanBook(false)
    setIsAvailable(available)
    if (userUid && roleCode === 'TUTEE' && available) {
      setCanBook(true)
    }

    if (userUid) {
      setAuthenticated(true)
    } else {
      setAuthenticated(false)
    }
  }, [forBooking, userUid, roleCode])

  const handleClose = () => {
    setForBooking(null)
    setCanBook(false)
  }

  const proceedPayment = useCallback(() => {
    const iTutorUid = _.has(tutor, 'uid') ? tutor.uid : null
    const tutorUserUid = _.has(tutorProfile, 'userUid')
      ? tutorProfile.userUid
      : null
    const firstName = _.has(tutorProfile, 'firstName')
      ? tutorProfile.firstName
      : null
    const middleInitial = _.has(tutorProfile, 'middleInitial')
      ? tutorProfile.middleInitial
      : null
    const lastName = _.has(tutorProfile, 'lastName')
      ? tutorProfile.lastName
      : null

    navigate('/payments/checkout', {
      state: {
        timeSlotUid,
        title,
        price,
        sessionType,
        startDate,
        endDate,
        tutor: {
          uid: iTutorUid,
          userUid: tutorUserUid,
          firstName,
          middleInitial,
          lastName
        }
      }
    })
  }, [
    timeSlotUid,
    title,
    price,
    sessionType,
    dateRange,
    startDate,
    endDate,
    tutorProfile,
    tutor
  ])

  const [checkEnrolled, { loading: loadingCheckEnrolled }] = useLazyQuery(
    isEnrolledQuery
  )
  const [checkSessionSlot, { loading: loadingSessionSlot }] = useLazyQuery(
    hasSessionSlotQuery
  )

  useEffect(() => {
    if (instanceUid && timeSlotUid) {
      checkEnrolled({
        fetchPolicy: 'network-only',
        variables: {
          instanceUid,
          timeslotUid: timeSlotUid,
          startDate,
          endDate
        }
      }).then(({ data }) => {
        const alreadyEnrolled = _.has(data, 'isStudentEnrolled')
          ? data.isStudentEnrolled
          : null

        if (alreadyEnrolled) {
          setCanBook(false)
        }

        setEnrolled(alreadyEnrolled)
      })
    }
  }, [
    checkEnrolled,
    timeSlotUid,
    instanceUid,
    loadingCheckEnrolled,
    setLoading
  ])

  useEffect(() => {
    async function doCheck() {
      checkSessionSlot({
        variables: {
          timeslotUid: timeSlotUid,
          startDate,
          endDate
        }
      }).then(({ data }) => {
        const valid = _.has(data, 'hasSessionSlot') ? data.hasSessionSlot : null

        if (!valid) {
          setCanBook(false)
          setEnrolled(false)
          setIsFullyBooked(true)
        }
      })
    }

    if (timeSlotUid && startDate && endDate) {
      doCheck()
    }
  }, [timeSlotUid, startDate, endDate, checkSessionSlot])

  useEffect(() => {
    if (loadingSessionSlot || loadingSessionSlot) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [loadingCheckEnrolled, loadingSessionSlot])

  return (
    <Modal
      show={visible}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Book a Schedule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {enrolled && (
          <Row>
            <Col lg={12}>
              <Alert>
                <Alert.Heading>
                  <FontAwesomeIcon icon={solid('exclamation-circle')} /> Booked!
                </Alert.Heading>
                <p>You've already booked this timeslot</p>
              </Alert>
            </Col>
          </Row>
        )}
        {!isAvailable && (
          <Row>
            <Col lg={12}>
              <Alert variant='danger'>
                <Alert.Heading>
                  <FontAwesomeIcon icon={solid('exclamation-circle')} /> Date
                  Unavailable
                </Alert.Heading>
                <p>We are really sorry but this schedule has been taken.</p>
              </Alert>
            </Col>
          </Row>
        )}
        {isFullyBooked && (
          <Row>
            <Col lg={12}>
              <Alert variant='danger'>
                <Alert.Heading>
                  <FontAwesomeIcon icon={solid('exclamation-circle')} /> Session
                  Slot Full
                </Alert.Heading>
                <p>
                  This class has reached its maximum number of participants.
                </p>
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={3}>
            <p className='lead'>Interest</p>
          </Col>
          <Col lg={9}>
            <p className='lead'>
              <small className='text-muted'>{title}</small>
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg={3}>
            <p className='lead'>Price</p>
          </Col>
          <Col lg={9}>
            <p className='lead'>
              ₱ <small className='text-muted'>{price}</small>
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg={3}>
            <p className='lead'>Session</p>
          </Col>
          <Col lg={9}>
            <p className='lead'>
              <i className='far fa-user' />{' '}
              <small className='text-muted'>{sessionType}</small>
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg={3}>
            <p className='lead'>Time slot</p>
          </Col>
          <Col lg={9}>
            <p className='lead'>
              <i className='far fa-calendar-alt' />{' '}
              <small className='text-muted'>{dateRange}</small>
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        {authenticated ? (
          <Button
            variant='primary'
            onClick={proceedPayment}
            disabled={!canBook || loading}
          >
            {loading ? 'Loading...' : 'Proceed to checkout'}
          </Button>
        ) : (
          <LoginModal />
        )}
      </Modal.Footer>
    </Modal>
  )
}
