import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import _ from 'lodash'
import { Button, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Error400, LoadingSpinner } from '../../components'
import TutorProfile from './tutorProfile'
import StudentProfile from './studentProfile'
import {
  getElasticSearchQuery,
  getUserQuery,
  saveElasticSearchMutation
} from './gql'
import RegistryClient from '../../RegistryClient'
import ProfileContext from './profile.context'
import useInsertViewProfile from '../auditTrail/useInsertViewProfile'

export default function Index() {
  const [roleCode, setRoleCode] = useState(null)
  const { userUid } = useParams()
  const navigate = useNavigate()

  if (!userUid) {
    return <Error400>Invalid userUid</Error400>
  }

  const { doInsertViewProfile, userFullName } = useInsertViewProfile()

  const [getElasticSearch] = useLazyQuery(getElasticSearchQuery)
  const [updateElasticSearch] = useMutation(saveElasticSearchMutation)

  useEffect(() => {
    async function doUpdateElasticSearch() {
      const instance = await getElasticSearch({
        variables: { userUid }
      }).then(({ data }) =>
        _.has(data, 'getElasticSearch') ? data.getElasticSearch : null
      )
      const iDocument = _.has(instance, 'document') ? instance.document : null
      const iSearched = _.has(iDocument, 'searched') ? iDocument.searched : null
      const profile = _.has(iSearched, 'profile') ? iSearched.profile : 0

      let document = null

      switch (roleCode) {
        case 'TUTOR':
          document = {
            searched: {
              ...iSearched,
              profile: profile + 1
            }
          }
          break
        default:
          document = {
            viewed: {
              ...iSearched,
              profile: profile + 1
            }
          }
      }

      updateElasticSearch({
        variables: {
          referenceUid: userUid,
          type: roleCode,
          document
        }
      })
    }

    doUpdateElasticSearch()
  }, [userUid, roleCode])

  const { loading, data: userResult } = useQuery(getUserQuery, {
    client: RegistryClient,
    variables: { uid: userUid },
    skip: !userUid
  })

  useEffect(() => {
    const user = _.has(userResult, 'getUser') ? userResult.getUser : null
    const iRoleCode = _.has(user, 'roleCode') ? user.roleCode : null
    const userProfile = _.has(user, 'userProfile') ? user.userProfile : null
    const firstName = _.has(userProfile, 'firstName')
      ? userProfile.firstName
      : null
    const lastName = _.has(userProfile, 'lastName')
      ? userProfile.lastName
      : null
    const uid = _.has(user, 'uid') ? user.uid : null

    if (user) {
      doInsertViewProfile({
        profileUserUid: uid,
        changes: `${userFullName} viewed ${firstName} ${lastName}'s profile.`,
        module: 'Profile'
      })
    }

    setRoleCode(iRoleCode)
  }, [userResult])

  const renderer = () => {
    switch (roleCode) {
      case 'TUTOR':
        return <TutorProfile />

      default:
        return <StudentProfile />
    }
  }

  const contextPayload = useMemo(
    () => ({
      userUid
    }),
    [userUid]
  )

  const onBack = () => {
    navigate(-1)
  }

  return (
    <ProfileContext.Provider value={contextPayload}>
      <div className='content'>
        <div className='container-fluid'>
          <Row>
            <Col>
              <Button variant='link' size='large' onClick={onBack}>
                <FontAwesomeIcon icon={solid('circle-arrow-left')} /> Back
              </Button>
            </Col>
          </Row>

          {loading ? <LoadingSpinner /> : renderer()}
        </div>
      </div>
    </ProfileContext.Provider>
  )
}
