import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useMutation } from '@apollo/client'
import ls from 'local-storage'
import { signInWithCustomToken } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import Promise from 'bluebird'
import LoginUser from '../../template/login'
import client from '../../RegistryClient'
import {
  validateUser,
  getUserTutorDetailsQuery,
  getUserTuteeDetailsQuery,
  sendNotificationMutation,
  updateTutorElasticSearchMutation
} from './gql'
import LoginContext, { LoginPageContext } from './login.context'
import auth from '../../authEmulatorConnect'
import coreClient from '../../CoreClient'
import NotificationClient from '../../NotificationClient'
import { requestNotificationPermission } from '../../firebaseInit'
import { SAVE_REGISTRATION_TOKEN } from '../notification/gql'

export { default as LoginContext } from './login.context'

export default function Login() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [updateGradeLevel, setRequireGradelevelUpdate] = useState(null)

  const {
    setUserId,
    setUserUid,
    setRoleCode,
    setTokenManager,
    instanceUid,
    setInstanceUid,
    userUid,
    roleCode: iRoleCode,
    notifToken,
    setNotifToken
  } = useContext(LoginContext)

  const [saveRegistrationToken] = useMutation(SAVE_REGISTRATION_TOKEN, {
    client: NotificationClient
  })

  useEffect(() => {
    if (userUid) {
      if (!notifToken) {
        requestNotificationPermission(setNotifToken).then((nToken) => {
          if (nToken) {
            saveRegistrationToken({
              variables: {
                registrationToken: nToken,
                userUid
              }
            })
          }
        })
      }
      navigate('/')
    }
  }, [userUid])

  if (instanceUid) {
    ls.set('instanceUid', instanceUid)
  }

  const getTutorInstance = (payload) =>
    new Promise((resolve, reject) => {
      console.log({ payload })
      coreClient
        .query({
          query: getUserTutorDetailsQuery,
          variables: { ...payload }
        })
        .then(({ data }) => {
          const tutor = _.has(data, 'getUserTutorDetails')
            ? data.getUserTutorDetails
            : null
          const tutorUid = _.has(tutor, 'uid') ? tutor.uid : null
          console.log({ tutor })
          setInstanceUid(tutorUid)

          resolve(tutor)
        })
        .catch((err) => reject(err))
    })

  const getTuteeInstance = (payload) =>
    new Promise((resolve, reject) => {
      coreClient
        .query({
          query: getUserTuteeDetailsQuery,
          variables: { ...payload }
        })
        .then(({ data }) => {
          const tutee = _.has(data, 'getUserTuteeDetails')
            ? data.getUserTuteeDetails
            : null
          const tuteeUid = _.has(tutee, 'uid') ? tutee.uid : null

          setInstanceUid(tuteeUid)

          resolve(tutee)
        })
        .catch((err) => reject(err))
    })

  const [sendNotif] = useMutation(sendNotificationMutation, {
    client: NotificationClient
  })

  useEffect(() => {
    if (userUid && updateGradeLevel && iRoleCode === 'TUTEE') {
      sendNotif({
        variables: {
          title: 'Grade Level Update Required',
          message:
            'System require you to update your grade level yearly. Please update as soon as possible.',
          users: [userUid],
          data: {
            urlPath: '/student/my-profile'
          },
          createdBy: userUid
        }
      })
    }
  }, [userUid, updateGradeLevel, iRoleCode])

  // useEffect(() => {
  //   if (notifToken) {
  //     console.log('CALLED SAVE REGISTRATION TOKE');
  //     saveRegistrationToken({
  //       variables: {
  //         registrationToken: notifToken,
  //         userUid,
  //       },
  //     });
  //   }
  // }, [notifToken, userUid]);

  const [updateElasticSearch] = useMutation(updateTutorElasticSearchMutation)

  const [login] = useMutation(validateUser, {
    client,
    onCompleted: async (result) => {
      const { validateUser: data } = result
      const { token, user: userDetails } = data
      const { id, uid, roleCode, emailSent } = userDetails
      const variables = { userUid: uid }
      const requireGradelevelUpdate = _.has(
        userDetails,
        'requireGradelevelUpdate'
      )
        ? userDetails.requireGradelevelUpdate
        : null

      if (requireGradelevelUpdate) {
        // send grade level update notif
        setRequireGradelevelUpdate(true)
      }

      // get instanceUid(tutorUid || TuteeUid)
      if (roleCode === 'TUTOR') {
        await getTutorInstance(variables)
      }

      if (roleCode === 'TUTEE') {
        await getTuteeInstance(variables)
      }

      await signInWithCustomToken(auth, token)
        .then(async (userCredential) => {
          // Signed in
          // const { user, _tokenResponse } = userCredential;
          const { _tokenResponse } = userCredential
          const tokenManager = { accessToken: token, ..._tokenResponse }

          ls.set('tokenManager', tokenManager)
          ls.set('userId', id)
          ls.set('userUid', uid)
          ls.set('roleCode', roleCode)
          ls.set('emailSent', emailSent || false)

          setUserId(id)
          setUserUid(uid)
          setTokenManager(tokenManager)
          setRoleCode(roleCode)

          setError(null)

          switch (roleCode) {
            case 'TUTOR':
              updateElasticSearch({ variables: { tutorUserUid: uid } })
              navigate('/tutor', { replace: true })
              break
            case 'SUPERADMIN':
              navigate('/superadmin', { replace: true })
              break
            case 'MARKETING_ADMIN':
              navigate('/marketing-admin', { replace: true })
              break
            case 'PARTNER_MERCHANT':
              navigate('/partner-merchant', { replace: true })
              break
            case 'SUPPORT':
              navigate('/support', { replace: true })
              break
            default:
              navigate('/student')
          }

          // ...
        })
        .catch((err) => {
          // const errorCode = err.code;
          const errorMessage = err.message
          setError(errorMessage)
          // ...
        })

      setLoading(false)
    },
    onError: (err) => {
      const { message } = err
      setError(message || JSON.stringify(err))
      setLoading(false)
    }
  })

  const onSubmit = useCallback((payload) => {
    setLoading(true)

    login({ variables: { ...payload } })
  })

  const loginContextPayload = useMemo(
    () => ({
      loading,
      onSubmit,
      error,
      setError
    }),
    [loading, onSubmit, error, setError]
  )

  return (
    <LoginPageContext.Provider value={loginContextPayload}>
      <LoginUser error={error} loading={loading} />
    </LoginPageContext.Provider>
  )
}
