import { useQuery } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { LoginContext } from '../../../login'
import { getUserQuery } from './gql'
import RegistryClient from '../../../../RegistryClient'
import ForReviewModal from './forReviewModal'
import { Avatar, TutorRating } from '../../../../components'
import UploadAvatarModal from './uploadAvatarModal'
import ChangePasswordModal from './changePasswordModal'
import DeleteUser from './deleteUser'

export { default as AboutTutor } from './about'

const STATUS_FOR_APPLICATION = 'FOR_APPLICATION'
const STATUS_FOR_REVIEW = 'FOR_REVIEW'

export default function Index() {
  const { userUid, instanceUid } = useContext(LoginContext)
  const [email, setEmail] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [middleInitial, setMiddleInitial] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [birthDate, setBirthDate] = useState(null)
  const [mobile, setMobile] = useState(null)
  const [age, setAge] = useState(null)
  const [gender, setGender] = useState(null)
  const [address1, setAddress1] = useState(null)
  const [address2, setAddress2] = useState(null)
  const [city, setCity] = useState(null)
  const [postalCode, setPostalCode] = useState(null)
  const [countryCode, setCountryCode] = useState(null)
  const [userStatus, setUserStatus] = useState(null)
  const [userStatusLabel, setUserStatusLabel] = useState(null)
  const [statusVariant, setStatusVariant] = useState('default')

  const { data: userResult } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid }
  })

  useEffect(() => {
    const user = _.has(userResult, 'getUser') ? userResult.getUser : null
    const iUserStatus = _.has(user, 'status') ? user.status : null
    const profile = _.has(user, 'userProfile') ? user.userProfile : null
    const iEmail = _.has(user, 'email') ? user.email : null
    const iFirstName = _.has(profile, 'firstName') ? profile.firstName : null
    const iMI = _.has(profile, 'middleInitial') ? profile.middleInitial : null
    const iLastName = _.has(profile, 'lastName') ? profile.lastName : null
    const iBirthDate =
      _.has(profile, 'birthDate') && !!profile.birthDate
        ? new Date(profile.birthDate)
        : null
    const iGender = _.has(profile, 'gender') ? profile.gender : null
    const iAge = _.has(profile, 'age') ? profile.age : null
    const iMobile = _.has(profile, 'mobile') ? profile.mobile : null
    const iAddress = _.has(profile, 'address') ? profile.address : null
    const iAddress1 = _.has(iAddress, 'address1') ? iAddress.address1 : null
    const iAddress2 = _.has(iAddress, 'address2') ? iAddress.address2 : null
    const iCity = _.has(iAddress, 'city') ? iAddress.city : null
    const iPostalCode = _.has(iAddress, 'postalCode')
      ? iAddress.postalCode
      : null
    const iCountryCode = _.has(iAddress, 'countryCode')
      ? iAddress.countryCode
      : null

    switch (iUserStatus) {
      case STATUS_FOR_APPLICATION:
        setStatusVariant('text-warning')
        setUserStatusLabel('For Completion of Application')
        break
      case STATUS_FOR_REVIEW:
        setStatusVariant('text-warning')
        setUserStatusLabel('Application for Review')
        break
      case 'ACTIVE':
        setStatusVariant('text-success')
        setUserStatusLabel('Active')
        break
      case 'INACTIVE':
        setStatusVariant('text-danger')
        setUserStatusLabel('Inactive')
        break
      default:
        setStatusVariant('default')
    }

    setEmail(iEmail)
    setFirstName(iFirstName)
    setMiddleInitial(iMI)
    setLastName(iLastName)
    setUserStatus(iUserStatus)

    setBirthDate(iBirthDate)
    setGender(iGender)
    setAge(iAge)
    setMobile(iMobile)
    setAddress1(iAddress1)
    setAddress2(iAddress2)
    setCity(iCity)
    setPostalCode(iPostalCode)
    setCountryCode(iCountryCode)
  }, [userResult])

  return (
    <>
      <div className='card col-10 me-auto ms-auto p-0'>
        <div className='card-body'>
          <div className='mentor-widget'>
            <div className='user-info-left align-items-center'>
              <div className='mentor-img d-flex flex-wrap justify-content-center'>
                <Avatar />
                <TutorRating tutorUid={instanceUid} />
                <div className='mentor-details m-0'>
                  <p className='user-location m-0'>
                    <i className='fas fa-map-marker-alt' /> {city},{' '}
                    {countryCode}
                  </p>
                </div>
              </div>
              <div className='user-info-cont'>
                <h4 className='usr-name'>
                  {firstName || ''} {middleInitial || ''} {lastName || ''}
                </h4>
                {/* <p className="mentor-type">English Literature (M.A)</p> */}
                <div className='mentor-action'>
                  <p className='mentor-type social-title'>
                    Status{' '}
                    <span className={statusVariant}>{userStatusLabel}</span>
                  </p>

                  <UploadAvatarModal />
                  <ChangePasswordModal />
                  <DeleteUser />

                  {userStatus === STATUS_FOR_APPLICATION && <ForReviewModal />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='card col-10 me-auto ms-auto p-0'>
        <div className='card-body custom-border-card pb-0'>
          <div className='widget education-widget mb-0'>
            <h4 className='widget-title'>Personal Details</h4>
            <hr />
            <div className='experience-box'>
              <ul className='experience-list profile-custom-list'>
                <li>
                  <div className='experience-content'>
                    <div className='timeline-content'>
                      <span>First Name</span>
                      <div className='row-result'>{firstName}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='experience-content'>
                    <div className='timeline-content'>
                      <span>Middle Initial</span>
                      <div className='row-result'>{middleInitial}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='experience-content'>
                    <div className='timeline-content'>
                      <span>Last Name</span>
                      <div className='row-result'>{lastName}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='experience-content'>
                    <div className='timeline-content'>
                      <span>Gender</span>
                      <div className='row-result'>{gender}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='experience-content'>
                    <div className='timeline-content'>
                      <span>Date of Birth</span>
                      <div className='row-result'>
                        {birthDate && moment(birthDate).format('ll')}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='experience-content'>
                    <div className='timeline-content'>
                      <span>Age</span>
                      <div className='row-result'>{age}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='card col-10 me-auto ms-auto p-0'>
        <div className='card-body custom-border-card pb-0'>
          <div className='widget education-widget mb-0'>
            <h4 className='widget-title'>Contact</h4>
            <hr />
            <div className='experience-box'>
              <ul className='experience-list profile-custom-list'>
                <li>
                  <div className='experience-content'>
                    <div className='timeline-content'>
                      <span>Email</span>
                      <div className='row-result'>{email}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='experience-content'>
                    <div className='timeline-content'>
                      <span>Mobile</span>
                      <div className='row-result'>{mobile}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='card col-10 me-auto ms-auto p-0'>
        <div className='card-body custom-border-card pb-0'>
          <div className='widget awards-widget m-0'>
            <h4 className='widget-title'>Location</h4>
            <hr />
            <div className='experience-box'>
              <ul className='experience-list profile-custom-list'>
                <li>
                  <div className='experience-content'>
                    <div className='timeline-content'>
                      <span>Address 1</span>
                      <div className='row-result'>{address1}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='experience-content'>
                    <div className='timeline-content'>
                      <span>Address 2</span>
                      <div className='row-result'>{address2}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='experience-content'>
                    <div className='timeline-content'>
                      <span>Country</span>
                      <div className='row-result'>{countryCode}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='experience-content'>
                    <div className='timeline-content'>
                      <span>City</span>
                      <div className='row-result'>{city}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='experience-content'>
                    <div className='timeline-content'>
                      <span>Postal Code</span>
                      <div className='row-result'>{postalCode}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
